// @flow

import React from 'react'
import { graphql } from 'gatsby'

import { withIntl } from 'src/intl'

import { createSubjectsAndSubSectionsArray } from 'src/common/utils'
import {
  Layout,
  Footer,
  Navbar,
  BackgroundImage,
  PageRow,
  Heading,
} from 'src/common/components'

import { TopicListVocab } from 'src/components/TopicList'
import TopicWrapper from 'src/components/TopicWrapper'
import HeadTags from 'src/components/HeadTags'

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {|
  data: Object,
  pageContext: PageContext,
|}

function Vocab({ data, pageContext }: Props) {
  const posts = data.allMarkdownRemark.edges
  const { meta, intro } = data.markdownRemark.frontmatter
  const { footer } = data.footer.frontmatter

  const subjectsAndSubSectionsArray = createSubjectsAndSubSectionsArray(posts)
  return (
    <>
      <HeadTags pageContext={pageContext} {...meta} />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <BackgroundImage />,
          footer: <Footer content={footer} />,
          body: (
            <PageRow firstRow>
              <Heading text={intro.heading} />
              <TopicWrapper>
                {subjectsAndSubSectionsArray.map((item, i) => {
                  return (
                    <TopicListVocab
                      key={i}
                      subject={item.subject}
                      definitions={item.SubSections}
                    />
                  )
                })}
              </TopicWrapper>
            </PageRow>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(Vocab)

export const pageQuery = graphql`
  query VocabPosts($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...MetaFragment
        intro {
          heading
          description
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { locale: { eq: $locale }, pageType: { eq: "vocab-post" } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            subject
          }
        }
      }
    }
    ...FooterFragment
  }
`
